import { FC } from "react";
import { Button, List, Modal } from "antd";
import { useStore } from "../../state/stores/store";
import { Sentence } from "../../consts/wordDocument.type";
import styled from "styled-components";
import { capitalizeFirstLetter } from "../../utils/stringUtil";

const KanajiDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Kanjilist: FC = (props) => {
  const kanji = useStore((state) => state.selectedWord.kanji);

  //   const data = [
  // {
  //   title: "Reading",
  //   val: selectedWord.word_data.reading,
  // },
  // {
  //   title: "English",
  //   val: renderEng(selectedWord.word_data.english_definitions ?? ""),
  //   //   val: selectedWord._id,
  // },
  // {
  //   title: "Sentences",
  //   //   val: selectedWord.sentences,
  //   val: renderSentences(selectedWord.sentences),
  // },
  // {
  //   title: "Tags",
  //   val: rowData.tags,
  // },
  //   ];

  const KanjiDataInfoLabel = styled.span`
    color: black;
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
  `;

  const KanjiDataInfo = styled.span`
    color: black;
    font-size: 14px;
    font-weight: 400;
  `;

  const getReadings = (kanjiReadings: any) => {
    return kanjiReadings.map((kanjiReading: any) => (
      <div>
        <KanjiDataInfoLabel>
          {kanjiReading.type === "kun" ? "Kun" : "On"}:
        </KanjiDataInfoLabel>
        <KanjiDataInfo>{kanjiReading.val}</KanjiDataInfo>
      </div>
    ));
  };

  const labelAndData = (label: string, data: any) => {
    return (
      <div>
        <KanjiDataInfoLabel>{label}: </KanjiDataInfoLabel>
        <KanjiDataInfo>{data}</KanjiDataInfo>
      </div>
    );
  };

  const getKanjiData = (kanji: any) => {
    return (
      <KanajiDataContainer>
        {labelAndData("Strokes", kanji.strokes)}
        {labelAndData(
          "Readings",
          kanji.main_meanings
            .map((meaning: string) => capitalizeFirstLetter(meaning))
            .join(", ")
        )}
        {getReadings(kanji.main_readings)}
        {/* {labelAndData("Radical", kanji.radical.basis)} */}
      </KanajiDataContainer>
    );
  };

  return (
    <List
      //   style={{ maxHeight: 400, overflowY: "scroll" }}
      itemLayout="horizontal"
      dataSource={kanji}
      renderItem={(item, index) => {
        console.log({ item });
        return (
          <List.Item>
            <List.Item.Meta
              style={{ color: "black" }}
              title={<span style={{ fontSize: "18px" }}>{item.kanji}</span>}
              description={getKanjiData(item)}
            />
          </List.Item>
        );
      }}
    />
  );
};

export default Kanjilist;
