import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { usePapaParse } from "react-papaparse";
import { Breadcrumb, Layout, List, Menu, theme } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import type { GetProps } from "antd";
import { useCSVReader } from "react-papaparse";
import {
  EditOutlined,
  EllipsisOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Avatar, Card } from "antd";
const { Meta } = Card;
type SearchProps = GetProps<typeof Input.Search>;

const { Search } = Input;

const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);

const { Header, Content, Footer } = Layout;

const items = new Array(3).fill(null).map((_, index) => ({
  key: String(index + 1),
  label: `nav ${index + 1}`,
}));

const data = [
  "Racing car sprays burning fuel into crowd.",
  "Japanese princess to wed commoner.",
  "Australian walks 100km after outback crash.",
  "Man charged over missing wedding girl.",
  "Los Angeles battles huge wildfires.",
];

const Lookup: React.FC = () => {
  const getKanjiData = () => {
    return [
      `KKLC  ${selectedKanji.NUMBER}`,
      `White Rabbit  ${selectedKanji["WR#"]}`,
      `White Rabbit Volume ${selectedKanji.WR}`,
    ];
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { readString } = usePapaParse();

  const [kanji, setKanji] = useState([]) as any;
  const [selectedKanji, setSelectedKanji] = useState({}) as any;

  const onSearch: SearchProps["onSearch"] = (value, _e, info) => {
    console.log({ value });
    console.log({ kanji });

    const found = kanji.find((k: any) => {
      return k["KANJI"] == value;
    });

    setSelectedKanji(found);

    console.log({ found });
  };

  useEffect(() => {
    fetch("./kklc-wr.csv").then((response) => {
      //   const csvString = "kklc-wr.csv";
      console.log(response);
      const csvString = response.text();
      csvString.then((csvResp) => {
        readString(csvResp, {
          worker: true,
          delimiter: ",",
          complete: (results) => {
            console.log("---------------------------");
            console.log({ results });
            const { data } = results;
            const headers = data.shift() as Array<any>;

            const localK = (results?.data || []).slice(1) as Array<any>;

            let kanjiObjArr = [];
            for (let row of localK) {
              let obj: Record<string, any> = {};
              headers.forEach((header: string, index: number) => {
                obj[header] = row[index];
              });
              kanjiObjArr.push(obj);
            }

            setKanji(kanjiObjArr);

            // setKanji(localK);
            console.log("---------------------------");
          },
        });
      });
    });
  }, []);

  // const getKanjiData = () => {
  //   return [] as Array<string>;
  // };

  return (
    <Layout>
      <Content style={{ padding: "0 48px" }}>
        <div
          style={{
            padding: 24,
            minHeight: 380,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Search
            placeholder="Search Kanji"
            allowClear
            enterButton="Search"
            size="large"
            style={{ maxWidth: "300px" }}
            onSearch={onSearch}
          />

          {selectedKanji.KANJI && (
            <Card
              style={{ width: 300 }}
              cover={
                <span
                  style={{
                    display: "flex",
                    fontSize: "100px",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  {selectedKanji.KANJI}
                </span>
              }
              actions={[
                <SettingOutlined key="setting" />,
                <EditOutlined key="edit" />,
                <EllipsisOutlined key="ellipsis" />,
              ]}
            >
              <Meta
                // avatar={
                //   // <Avatar src="https://api.dicebear.com/7.x/miniavs/svg?seed=8" />
                // }
                title={selectedKanji.KANJI ?? "title"}
                description={selectedKanji.MEANING ?? "meaning"}
              />
              <List
                size="small"
                // header={<div>Header</div>}
                // footer={<div>Footer</div>}
                bordered
                dataSource={getKanjiData()}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            </Card>
          )}
        </div>
      </Content>
      {/* <Footer style={{ textAlign: "center" }}>
        Ant Design ©{new Date().getFullYear()} Created by Ant UED
      </Footer> */}
    </Layout>
  );
};

export default Lookup;
