const capitalizeFirstLetter = (s:string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
}

const formatJlptList = (jlpt: Array<string>) => {
    return jlpt.map((word)=>{
        return formatJlptWord(word)
    }).join("")
}

const formatJlptWord = (s: string) => {
    return s.slice(s.length-2, s.length).toLocaleUpperCase()
}

export {capitalizeFirstLetter, formatJlptList, formatJlptWord} 