import React, { FC, useState } from "react";
import { Button, Card, List, Modal, Tag } from "antd";
import styled from "styled-components";
import { useStore } from "../../state/stores/store";
import { Sentence } from "../../consts/wordDocument.type";
import SentenceList from "./sentenceList";
import { getTags } from "../../utils/dataDisplayUtil";
import Kanjilist from "./kanjiList";
import { formatJlptList } from "../../utils/stringUtil";

const Container = styled.div`
  //   align-items: center;
  //   display: flex;
  //   justify-content: center;
  //   height: 100%;
`;

const FlashCardText = styled.span`
  font-size: 50px;
`;

const data = [
  {
    title: "Title 1",
  },
  {
    title: "Title 2",
  },
  {
    title: "Title 3",
  },
  {
    title: "Title 4",
  },
  {
    title: "Title 5",
  },
  {
    title: "Title 6",
  },
];

const InfoModal: FC = (props) => {
  const isInfoModalOpen = useStore((state) => state.isInfoModalOpen);
  const setIsInfoModalOpen = useStore((state) => state.setIsInfoModalOpen);
  const selectedWord = useStore((state) => state.selectedWord);
  const windowWidth = useStore((state) => state.window);

  // console.log({ selectedWord });
  // _id: string;
  // categories: Array<string>;
  // kanji: Array<Kanji>;
  // sentences: Array<Sentence>;
  // word: string;
  // word_data: WordDocumentData;
  //   const { isModalOpen, setIsModalOpen, word } = props;
  const {
    kanji = [],
    categories = [],
    sentences = [],
    word = "",
    word_data: {},
  } = selectedWord;
  const [isFront, setIsFront] = useState(true);
  const [i, seti] = useState(0);

  const showModal = () => {
    setIsInfoModalOpen(true);
  };

  const handleOk = () => {
    setIsInfoModalOpen(false);
  };

  const handleCancel = () => {
    setIsInfoModalOpen(false);
  };

  const capitalize = (s: string) => {
    if (!s) return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const renderEng = (eng: Array<string>) => {
    return eng
      .map((word: string) => {
        return capitalize(word);
      })
      .join(", ");

    return;

    eng.map((word) => capitalize(word)).join(", ");
  };

  const renderSentences = (sentences: Array<Sentence>) => {
    return sentences.map((sentence: Sentence) => {
      const { en_translation, japanese } = sentence;
      return (
        <div>
          <span>{en_translation}</span>
          <span>{japanese}</span>
        </div>
      );
    });
  };

  const shouldNotShow = (item: any) => {
    const { title, val } = item;
    if (title === "Sentences" && selectedWord.sentences.length === 0)
      return true;
    // if(title === "")
    return false;
  };

  const getDisplayableTags = (tags: Array<string>) => {
    return (
      <span>
        {(tags ?? []).map((tag: any) => {
          if (tag === "NONE" || tag === "False") return <></>;
          if (tag === "Common")
            return (
              <Tag key={tag} color="green">
                COMMON
              </Tag>
            );
          return <Tag key={tag}>{tag.toUpperCase()}</Tag>;
        })}
      </span>
    );
  };

  const data = [
    // {
    //   title: "Reading",
    //   val: selectedWord.word_data.reading,
    // },
    // {
    //   title: "English",
    //   val: renderEng(selectedWord.word_data.english_definitions ?? ""),
    //   //   val: selectedWord._id,
    // },

    // {
    //   title: "Reading",
    //   val: selectedWord.word_data.reading,
    // },

    {
      title: "Kanji",
      val: <Kanjilist />,
    },
    {
      title: "Sentences",
      // val: <></>,
      val: <SentenceList />,
    },
  ];

  const data2 = [
    {
      title: "Word",
      val: selectedWord.word,
    },
    {
      title: "Reading",
      val: selectedWord.word_data.reading,
    },
    {
      title: "Translations",
      val: renderEng(selectedWord.word_data.english_definitions ?? ""),
    },
    {
      title: "JLPT",
      val: formatJlptList(selectedWord.word_data.jlpt),
    },
    {
      title: "Tags",
      val: getDisplayableTags(
        getTags(selectedWord.word_data.is_common, selectedWord.categories)
      ),
    },
    {
      title: "Parts of Speech",
      val: selectedWord.word_data.parts_of_speech.join(", "),
    },
  ];

  return (
    <Modal
      open={isInfoModalOpen}
      // onOk={handleOk}
      onCancel={handleCancel}
      title={selectedWord.word}
      width={"95vw"}
      styles={{ body: { height: "70vh" } }}
      footer={<></>}
    >
      <Container style={{ maxHeight: "70vh", overflowY: "scroll" }}>
        <List
          style={{
            maxWidth: "98%",
            // display: "flex",
            // justifyContent: "center",
            // alignContent: "center",
          }}
          grid={{ gutter: 8, xs: 2, md: 4 }}
          dataSource={data2}
          renderItem={(item) => {
            console.log({ data2item: item });
            console.log({ selectedWord });

            return (
              <List.Item>
                <Card
                  title={item.title}
                  styles={{
                    header: {
                      justifyContent: "center",
                      display: "flex",
                    },
                    title: {
                      justifyContent: "center",
                      display: "flex",
                    },
                    //   body: { padding: windowWidth > 500 ? "24px" : "6px" },
                    body: {
                      // width: "20vw",
                      justifyContent: "center",
                      display: "flex",
                    },
                  }}
                >
                  {item.val}
                </Card>
              </List.Item>
            );
          }}
        />
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item, index) => {
            if (shouldNotShow(item)) return <></>;
            return (
              <List.Item>
                <List.Item.Meta
                  title={<span style={{ fontSize: "18px" }}>{item.title}</span>}
                  description={<>{item.val}</>}
                />
              </List.Item>
            );
          }}
        />
      </Container>
    </Modal>
  );
};

export default InfoModal;
