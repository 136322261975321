
import { Table, Tag } from "antd";

const getTags = (isCommon = false, categories: Array<string>) => {
    let tags = categories.map((category: string) => category);
    if (isCommon) tags.unshift("Common");
    return tags;
};



const getDisplayableTags = (tags: Array<string>) => {
    // return <div>
    // f
    // </div>
    // // (tags ?? []).map((tag: any) => {
    //         if (tag === "NONE" || tag === "False") return <></>;
    //         if (tag === "Common")
    //           return ( <Tag key={tag} color="green">
    //               COMMON
    //             </Tag>
    //           );
    //         return <Tag key={tag}>{tag.toUpperCase()}</Tag>;
    //       })



// {(tags ?? []).map((tag: any) => {
//     if (tag === "NONE" || tag === "False") return <></>;
//     if (tag === "Common")
//       return (
//         <Tag key={tag} color="green">
//           COMMON
//         </Tag>
//       );
//     return <Tag key={tag}>{tag.toUpperCase()}</Tag>;
//   })}
}


  
export {getTags}